import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Table",
        "componentName": "Table"
      }}>{`<Table
  columns={[
    { title: 'Title', field: 'title' },
    { title: 'Amount of page views', field: 'pageViews', textAlign: 'right' },
  ]}
  data={[
    {
      title: 'A very interesting article about fishes',
      pageViews: '7,654,964',
    },
    {
      title: 'Look at this!',
      pageViews: '13,223,568',
    },
    {
      title: 'How you can save the planet',
      pageViews: '420',
    },
  ]}
  emptyDescription="No articles have been published"
/>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Table" mdxType="Props" />
    <h2 {...{
      "id": "columns",
      "style": {
        "position": "relative"
      }
    }}>{`Columns`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p><inlineCode parentName="p">{`columns`}</inlineCode>{` defines the upper row of the table and tells the table rows where to
find the necessary data.`}</p>
    <p>{`This is an array of objects, one for each column. The structure of that object
is as follows:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`title`}</inlineCode>{` (required): The text that will be used in the table's header.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`field`}</inlineCode>{` (required): The key that corresponds to a key in the `}<inlineCode parentName="li">{`data`}</inlineCode>{` array's
objects.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`textAlign`}</inlineCode>{` (optional): How the column's text should be aligned.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  columns={[
    { title: 'Title', field: 'title' },
    { title: 'Amount of page views', field: 'pageViews', textAlign: 'right' },
  ]}
  emptyDescription="No articles have been published"
/>
`}</code></pre>
    <h2 {...{
      "id": "data",
      "style": {
        "position": "relative"
      }
    }}>{`Data`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Based on the fields defined in the `}<inlineCode parentName="p">{`columbs`}</inlineCode>{` prop, the `}<inlineCode parentName="p">{`data`}</inlineCode>{` object should be
an array that has objects with keys corresponing to the keys you defined.`}</p>
    <p>{`If a key would not exist on a row, a `}<inlineCode parentName="p">{`-`}</inlineCode>{` character will be shown.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  columns={[
    { title: 'Title', field: 'title' },
    { title: 'Amount of page views', field: 'pageViews', textAlign: 'right' },
  ]}
  data={[
    {
      title: 'A very interesting article about fishes',
      pageViews: '7,654,964',
    },
    {
      title: 'Look at this!',
      pageViews: '13,223,568',
    },
    {
      title: 'No one likes me',
    },
  ]}
  emptyDescription="No articles have been published"
/>
`}</code></pre>
    <h2 {...{
      "id": "empty-table",
      "style": {
        "position": "relative"
      }
    }}>{`Empty table`}</h2>
    <p><inlineCode parentName="p">{`emptyIcon`}</inlineCode>{` `}<inlineCode parentName="p">{`default: Search`}</inlineCode></p>
    <p><inlineCode parentName="p">{`emptyDesciption`}</inlineCode>{` `}<inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`If the `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is either not defined or an empty array, the table will
show as empty. You can customize the empty display by using `}<inlineCode parentName="p">{`emptyDescription`}</inlineCode>{`
and `}<inlineCode parentName="p">{`emptyIcon`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  columns={[
    { title: 'Title', field: 'title' },
    { title: 'Amount of page views', field: 'pageViews', textAlign: 'right' },
  ]}
  emptyIcon={Conditions}
  emptyDescription="No articles have been published"
/>
`}</code></pre>
    <h2 {...{
      "id": "tablelayout",
      "style": {
        "position": "relative"
      }
    }}>{`TableLayout`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`Defines the algorithm used to lay out table cells, rows, and columns.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Col 1', field: 'col1' },
    { title: 'Col 2', field: 'col2' },
    { title: 'Col 3', field: 'col3' },
    { title: 'Col 4', field: 'col4' },
  ]}
  data={[
    {
      col1: 'A much longer piece of content for demo purposes. More goes here.',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
    {
      col1: 'Example text',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="auto"
/>

<Table
  columns={[
    { title: 'Col 1', field: 'col1' },
    { title: 'Col 2', field: 'col2' },
    { title: 'Col 3', field: 'col3' },
    { title: 'Col 4', field: 'col4' },
  ]}
  data={[
    {
      col1: 'A much longer piece of content for demo purposes. More goes here.',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
    {
      col1: 'Example text',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
/>
`}</code></pre>
    <h2 {...{
      "id": "column-width",
      "style": {
        "position": "relative"
      }
    }}>{`Column width`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`Sets the width of the column. If you want to be sure to have a fixed width, use
tableLayout fixed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Col 1', field: 'col1', width: '700px' },
    { title: 'Col 2', field: 'col2' },
    { title: 'Col 3', field: 'col3' },
    { title: 'Col 4', field: 'col4' },
  ]}
  data={[
    {
      col1: 'This cell tries to be 700px',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
    {
      col1: 'Example text',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="auto"
/>

<Table
  columns={[
    { title: 'Col 1', field: 'col1', width: '700px' },
    { title: 'Col 2', field: 'col2' },
    { title: 'Col 3', field: 'col3' },
    { title: 'Col 4', field: 'col4' },
  ]}
  data={[
    {
      col1: 'This is really 700px',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
    {
      col1: 'Example text',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
/>
`}</code></pre>
    <h2 {...{
      "id": "ellipsis",
      "style": {
        "position": "relative"
      }
    }}>{`Ellipsis`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`If a collumn has ellipsis, overflowing texts will be cut and ellipsis will be
added.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Col 1', field: 'col1', width: '200px' },
    {
      title: 'Ea aliqua sit do ullamco mollit esse duis ad',
      field: 'col2',
      ellipsis: true,
    },
    { title: 'Col 3', field: 'col3' },
    { title: 'Col 4', field: 'col4' },
  ]}
  data={[
    {
      col1: {
        value: 'Ea aliqua sit do ullamco mollit esse duis ad.',
        ellipsis: true,
      },
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
    {
      col1: 'Example text',
      col2: 'Example text',
      col3: 'Example text',
      col4: 'Example text',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
/>
`}</code></pre>
    <h2 {...{
      "id": "sorting",
      "style": {
        "position": "relative"
      }
    }}>{`Sorting`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`Table`}</inlineCode>{` has a basic sorting feature that lets users sort data by any column.
To make it possible, set the property `}<inlineCode parentName="p">{`sorting`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Name', field: 'name' },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthyear' },
    { title: 'Birthplace', field: 'birthplace' },
  ]}
  data={[
    {
      name: 'Marilyn',
      surname: 'Monroe',
      birthyear: '1926',
      birthplace: 'Los Angeles',
    },
    {
      name: 'Abraham',
      surname: 'Lincoln',
      birthyear: '1809',
      birthplace: 'Hodgenville (Kentucky)',
    },
    {
      name: 'Paul',
      surname: 'McCartney',
      birthyear: '1942',
      birthplace: 'Liverpool',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
  sorting={true}
/>
`}</code></pre>
    <h3 {...{
      "id": "non-sorting-columns",
      "style": {
        "position": "relative"
      }
    }}>{`Non sorting columns`}</h3>
    <p>{`Make specific columns non-sortable by adding a `}<inlineCode parentName="p">{`sortable`}</inlineCode>{` key to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. In the
example below the `}<strong parentName="p">{`Name`}</strong>{` field `}<inlineCode parentName="p">{`sortable`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Name', field: 'name', sortable: false },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthyear' },
    { title: 'Birthplace', field: 'birthplace' },
  ]}
  data={[
    {
      name: 'Marilyn',
      surname: 'Monroe',
      birthyear: '1926',
      birthplace: 'Los Angeles',
    },
    {
      name: 'Abraham',
      surname: 'Lincoln',
      birthyear: '1809',
      birthplace: 'Hodgenville (Kentucky)',
    },
    {
      name: 'Paul',
      surname: 'McCartney',
      birthyear: '1942',
      birthplace: 'Liverpool',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
  sorting={true}
/>
`}</code></pre>
    <h2 {...{
      "id": "component-sorting-not-supported",
      "style": {
        "position": "relative"
      }
    }}>{`Component sorting not supported`}</h2>
    <p>{`The `}<strong parentName="p">{`default sorting method is only limited to non component data`}</strong>{` and will
cause errors when it tries to sort columns with components.That's why we
recommend giving that specific column a `}<inlineCode parentName="p">{`sortable: false`}</inlineCode>{` key.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    { title: 'Name', field: 'name' },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthyear' },
    { title: 'Birthplace', field: 'birthplace', sortable: false },
  ]}
  data={[
    {
      name: 'Marilyn',
      surname: 'Monroe',
      birthyear: '1926',
      birthplace: (
        <Button
          size="small"
          appearance="secondary"
          onClick={() => alert('Los Angeles')}
        >
          View birthplace
        </Button>
      ),
    },
    {
      name: 'Abraham',
      surname: 'Lincoln',
      birthyear: '1809',
      birthplace: (
        <Button
          size="small"
          appearance="secondary"
          onClick={() => alert('Hodgenville (Kentucky)')}
        >
          View birthplace
        </Button>
      ),
    },
    {
      name: 'Paul',
      surname: 'McCartney',
      birthyear: '1942',
      birthplace: (
        <Button
          size="small"
          appearance="secondary"
          onClick={() => alert('Liverpool')}
        >
          View birthplace
        </Button>
      ),
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
  sorting={true}
/>
`}</code></pre>
    <h2 {...{
      "id": "custom-sorting",
      "style": {
        "position": "relative"
      }
    }}>{`Custom sorting`}</h2>
    <p>{`It's also possible to pass a `}<inlineCode parentName="p">{`customSort`}</inlineCode>{` algorithm to specific columns. In the
example below the new algorithm sorts by length of string of `}<strong parentName="p">{`birthpllace`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Table
  mb={5}
  columns={[
    {
      title: 'Name',
      field: 'name',
    },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthyear' },
    {
      title: 'Birthpllace',
      field: 'birthplace',
      customSort: (a, b) => a.birthplace.length - b.birthplace.length,
    },
  ]}
  data={[
    {
      name: 'Marilyn',
      surname: 'Monroe',
      birthyear: '1926',
      birthplace: 'Los Angeles',
    },
    {
      name: 'Abraham',
      surname: 'Lincoln',
      birthyear: '1809',
      birthplace: 'Hodgenville (Kentucky)',
    },
    {
      name: 'Paul',
      surname: 'McCartney',
      birthyear: '1942',
      birthplace: 'Liverpool',
    },
  ]}
  emptyDescription="No articles have been published"
  tableLayout="fixed"
  sorting={true}
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      